import { Loading } from './FeedbackLoading';
import html2canvas from 'html2canvas';
import React, { useState } from 'react';
import { Button } from '@nextui-org/react';
import { ArrowLeft, X, Camera, Trash } from './FeedbackWidgetIcons';

interface ButtonProps {
  onClick: () => void;
}

export const BackButton: React.FC<ButtonProps> = ({ onClick }: ButtonProps) => {
  return (
    <button type="button" className="top-5 left-5 absolute text-dark-slate-blue hover:text-slate-600" onClick={onClick}>
      <ArrowLeft width={4} height={4} className="w-4 h-4" />
    </button>
  );
};

interface ScreenshotProps {
  screenshot: string | null;
  onTakeScreenshot: (screenshot: string | null) => void;
}

export const ScreenshotButton: React.FC<ScreenshotProps> = ({ screenshot, onTakeScreenshot }: ScreenshotProps) => {
  const [isTakingScreenshot, setIsTakingScreenshot] = useState(false);

  const handleScreenshot = async () => {
    setIsTakingScreenshot(true);

    const canvas = await html2canvas(document.querySelector('html')!);
    const base64Image = canvas.toDataURL('image/png');
    onTakeScreenshot(base64Image);

    setIsTakingScreenshot(false);
  };

  if (screenshot) {
    return (
      <button
        type="button"
        className="p-2 w-12 h-14 rounded-md border-black border-1 flex items-end justify-end text-old-lace hover:text-orange-100 transition-colors"
        style={{
          backgroundImage: `url(${screenshot})`,
        }}
        onClick={() => onTakeScreenshot(null)}
      >
        <Trash width={20} height={20} fill="black" />
      </button>
    );
  }

  return (
    <Button
      isIconOnly
      type="button"
      className="p-2 bg-dark-slate-blue rounded-md border-transparent hover:bg-light-steel-blue focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-600 transition-colors"
      onClick={handleScreenshot}
    >
      {isTakingScreenshot ? <Loading /> : <Camera width={32} height={32} fill="white" className="z-10" />}
    </Button>
  );
};
