import { setupI18n } from '@lingui/core';
import { setI18n } from '@lingui/react/server';
import linguiConfig from '@/lingui.config';

export const locales = linguiConfig.locales;
export type Locale = 'en' | 'fr' | 'pseudo';

type MessagesFile = {
  messages: {
    [key: string]: string;
  };
};

async function loadMessages(locale: string) {
  if (process.env.NODE_ENV !== 'production') {
    return ((await import(`@/locales/${locale}.po`)) as MessagesFile).messages;
  } else {
    return ((await import(`@/locales/${locale}`)) as MessagesFile).messages;
  }
}

export async function setLocale(locale: Locale) {
  const messages = await loadMessages(locale);

  const i18n = setupI18n({
    locale,
    messages: { [locale]: messages },
  });

  setI18n(i18n);

  return i18n;
}
