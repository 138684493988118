'use client';

import useFetcher from '@/lib/fetcher';
import { Loading } from './FeedbackLoading';
import React, { FormEvent, use, useState } from 'react';
import { FeedbackType, feedbackTypes } from './FeedbackForm';
import { BackButton, ScreenshotButton } from './FeedbackWidgetButtons';
import { FormButton } from '@/components/FormButton';
import { Trans, useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

interface Props {
  feedbackType: FeedbackType;
  onResetFeedbackType: () => void;
  onSubmitFeedback: () => void;
}

export const FeedbackContentStep: React.FC<Props> = ({
  feedbackType,
  onResetFeedbackType,
  onSubmitFeedback,
}: Props) => {
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fetcher, postFetcher } = useFetcher();

  const { i18n } = useLingui();

  const feedbackTypeData = feedbackTypes[feedbackType];

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setIsSubmitting(true);

    const feedback = await postFetcher('/api/feedback', { type: feedbackType, comment, screenshot }, {});
    console.log(feedback);

    setIsSubmitting(false);

    onSubmitFeedback();
  };

  return (
    <>
      <header>
        <BackButton onClick={onResetFeedbackType} />

        <span className="text-xl text-dark-slate-blue leading-6 flex items-center gap-2 h-20">
          <feedbackTypeData.image.src width={24} height={24} className="w-6 h-6" />
          <Trans id={feedbackTypeData.title.id} />
        </span>
      </header>

      <form className="my-4 w-full text-dark-slate-blue" onSubmit={handleSubmit}>
        <textarea
          placeholder={i18n._(feedbackTypeData.placeholder)}
          className="min-w-[260px] w-full min-h-[112px] text-sm  border-2 border-black bg-old-lace rounded-md scrollbar-thin scrollbar-thumb-dark-slate-blue scrollbar-track-transparent focus:border-slate-500 focus:ring-slate-500 focus:ring-1 focus:outline-none resize-none px-1 py-1 rounded-bl-3xl rounded-tr-3xl rounded-br-sm rounded-tl-sm"
          onChange={(event) => setComment(event.target.value)}
        />

        <footer className="flex mt-2 justify-between">
          <ScreenshotButton screenshot={screenshot} onTakeScreenshot={setScreenshot} />

          <FormButton
            text={i18n._(msg`Send Feedback`)}
            type="submit"
            isLoading={isSubmitting}
            disabled={comment.length === 0 || isSubmitting}
          />
        </footer>
      </form>
    </>
  );
};
