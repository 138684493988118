import React from 'react';

import { CircleNotch } from './FeedbackWidgetIcons';

export const Loading: React.FC = () => {
  return (
    <div className="w-6 h-6 flex items-center justify-center overflow-hidden">
      <CircleNotch width={4} height={4} className="w-4 h-4 animate-spin" />
    </div>
  );
};
