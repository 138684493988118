const locales = ['en', 'fr'];

if (process.env.NODE_ENV !== 'production') {
  locales.push('pseudo');
}

/** @type {import('@lingui/conf').LinguiConfig} */
const linguiConfig = {
  locales: locales,
  sourceLocale: 'en',
  pseudoLocale: 'pseudo',
  catalogs: [
    {
      path: 'locales/{locale}',
      include: ['app/', 'components/', 'lib/'],
      //   exclude: ['**/.next/**', '**/*.d.ts', '**/node_modules/**'],
    },
  ],
  format: 'po',
};

export default linguiConfig;
