'use client';

import React from 'react';

import { Success } from './FeedbackWidgetIcons';
import { FormButton } from '@/components/FormButton';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

interface Props {
  onResetFeedbackType: () => void;
}

export const FeedbackSuccessStep: React.FC<Props> = ({ onResetFeedbackType }: Props) => {
  const { i18n } = useLingui();

  return (
    <>
      <div className="flex flex-col items-center py-10 w-[304px]">
        <Success width={40} height={40} />
        <span className="text-xl my-2">
          <Trans>We appreciate the feedback</Trans>!
        </span>
        <FormButton type="button" onClick={onResetFeedbackType} text={i18n._(msg`Send more feedback`)} />
      </div>
    </>
  );
};
