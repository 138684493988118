'use client';
import React from 'react';
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
} from '@nextui-org/react';
import { FormButton } from '@/components/FormButton';
import { ParentButton } from '@/components/ParentButton';
import { Portal } from '@/lib/constants';
import { LanguageSwitcher } from '@/components/LanguageSwitcher';
import { useLingui } from '@lingui/react';
import { Trans, t } from '@lingui/macro';

export default function LayoutHeader({ portal }: { portal: Portal }) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { i18n } = useLingui();
  const locale = i18n.locale;

  const getBasicItems = () => [
    { label: t(i18n)`Kids`, href: `/${locale}/kidsLogin` },
    { label: t(i18n)`Causes`, href: `/${locale}/causes` },
    { label: t(i18n)`About`, href: `/${locale}/about` },
  ];

  const getPublicItems = () => [
    { label: t(i18n)`Home`, href: `/${locale}` },
    { label: t(i18n)`Parents`, href: `/${locale}/parents` },
    ...getBasicItems(),
  ];
  const getParentsItems = () => [{ label: t(i18n)`Home`, href: `/${locale}/parents` }, ...getBasicItems()];
  const adminItems = [...getParentsItems(), { label: 'Admin', href: `/${locale}/admin` }];

  const getKidsItems = () => [
    { label: t(i18n)`Home`, href: `/${locale}/kids/home` },
    { label: t(i18n)`Story`, href: `/${locale}/kids/story` },
    { label: t(i18n)`Donate`, href: `/${locale}/kids/donate` },
    { label: t(i18n)`Library`, href: `/${locale}/kids/library` },
    { label: t(i18n)`About`, href: `/${locale}/about` },
  ];

  const navItems =
    portal == Portal.Public
      ? getPublicItems()
      : portal == Portal.Admin
        ? adminItems
        : portal == Portal.Parents
          ? getParentsItems()
          : getKidsItems();

  return (
    <Navbar
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      className="bg-old-lace backdrop-blur-none backdrop-saturate-100 shadow sticky top-0 z-20 p-0 m-0"
      maxWidth="full"
    >
      <NavbarContent>
        <NavbarMenuToggle aria-label={isMenuOpen ? 'Close menu' : 'Open menu'} className="md:hidden" />
        <NavbarBrand className="">
          <Link href={`/${locale}`} className="mt-3">
            <img src="/static/CHCLogo.svg" width={60} height={60} alt="Chesed Club Logo" />
          </Link>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden md:flex gap-4" justify="center">
        {navItems.map((item, index) => (
          <NavbarItem key={`${item}-${index}`}>
            <Link className="uppercase font-bold font-titillium-web text-black" href={item.href}>
              {item.label}
            </Link>
          </NavbarItem>
        ))}
        <LanguageSwitcher />
      </NavbarContent>
      <NavbarContent justify="end">
        {portal == Portal.Public ? (
          <>
            <NavbarItem>
              <Link
                href={`/${locale}/parents`}
                className="p-1 border-3 border-black bg-dark-slate-blue min-w-max place-self-center font-titillium-web text-old-lace text-xl text-center font-bold hover:text-black hover:bg-light-steel-blue disabled:opacity-50"
              >
                <Trans>Log In</Trans>
              </Link>
            </NavbarItem>
            <NavbarItem>
              <Link
                href={`/${locale}/signup`}
                className="p-1 border-3 border-black min-w-max place-self-center font-titillium-web text-xl text-center font-bold text-black hover:text-old-lace hover:bg-goldenrod"
              >
                <Trans>Sign Up</Trans>
              </Link>
            </NavbarItem>
          </>
        ) : (
          <NavbarItem>
            <Link
              href={`/${locale}/logout`}
              className="p-1 border-3 border-black min-w-max place-self-center font-titillium-web text-xl text-center font-bold text-black hover:text-old-lace hover:bg-goldenrod"
            >
              <Trans>Log Out</Trans>
            </Link>
          </NavbarItem>
        )}
      </NavbarContent>
      <NavbarMenu>
        {navItems.map((item, index) => (
          <NavbarMenuItem key={`${item}-${index}`}>
            <Link className="w-full uppercase font-bold font-titillium-web text-black" href={item.href} size="lg">
              {item.label}
            </Link>
          </NavbarMenuItem>
        ))}
        <LanguageSwitcher />
      </NavbarMenu>
    </Navbar>
  );
}
