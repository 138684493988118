import { SVGProps } from 'react';

export const ArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
    <path d="M228,128a12,12,0,0,1-12,12H69l51.52,51.51a12,12,0,0,1-17,17l-72-72a12,12,0,0,1,0-17l72-72a12,12,0,0,1,17,17L69,116H216A12,12,0,0,1,228,128Z"></path>
  </svg>
);

export const Trash = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
    <path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
  </svg>
);

export const X = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
    <path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
  </svg>
);

export const CircleNotch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
    <path d="M232,128a104,104,0,0,1-208,0c0-41,23.81-78.36,60.66-95.27a8,8,0,0,1,6.68,14.54C60.15,61.59,40,93.27,40,128a88,88,0,0,0,176,0c0-34.73-20.15-66.41-51.34-80.73a8,8,0,0,1,6.68-14.54C208.19,49.64,232,87,232,128Z"></path>
  </svg>
);

export const ChatTeardropDots = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 512 383" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M500.104 85.9123V144.274C500.104 164.711 491.824 183.221 478.43 196.616C465.035 210.01 446.525 218.29 426.088 218.29V275.402L349.49 218.29H313.124V180.996C313.124 140.122 279.982 106.98 239.108 106.98H198.876V85.9123C198.876 45.0379 232.018 11.8959 272.892 11.8959H426.088C466.962 11.8959 500.104 45.0379 500.104 85.9123Z"
      fill="#8ECAC1"
    />
    <path
      d="M313.124 218.29V239.358C313.124 280.232 279.982 313.374 239.108 313.374H162.51L85.9123 370.486V313.374C65.4751 313.374 46.965 305.083 33.5703 291.688C20.1755 278.293 11.8959 259.795 11.8959 239.358V180.996C11.8959 140.122 45.0379 106.98 85.9123 106.98H198.876V144.274C198.876 185.148 232.018 218.29 272.892 218.29H313.124Z"
      fill="#FF7124"
    />
    <path
      d="M313.124 180.996V218.29H272.892C232.018 218.29 198.876 185.148 198.876 144.274V106.98H239.108C279.982 106.98 313.124 140.122 313.124 180.996Z"
      fill="#F2D59F"
    />
    <path
      d="M426.09 287.298C423.574 287.298 421.072 286.5 418.978 284.939L345.544 230.187H272.892C225.52 230.187 186.98 191.647 186.98 144.274V85.9123C186.98 38.5404 225.519 0 272.892 0H426.088C473.46 0 512 38.5392 512 85.9123V144.274C512 167.228 503.065 188.805 486.843 205.027C473.456 218.414 456.425 226.837 437.985 229.376V275.402C437.985 279.901 435.445 284.016 431.423 286.035C429.736 286.882 427.909 287.298 426.09 287.298ZM272.892 23.7918C238.639 23.7918 210.772 51.6592 210.772 85.9123V144.274C210.772 178.528 238.639 206.395 272.892 206.395H349.49C352.052 206.395 354.547 207.222 356.601 208.754L414.193 251.695V218.291C414.193 211.721 419.52 206.395 426.089 206.395C442.687 206.395 458.289 199.935 470.019 188.204C481.749 176.475 488.209 160.872 488.209 144.274V85.9123C488.209 51.6592 460.342 23.7918 426.089 23.7918H272.892Z"
      fill="#5E2A41"
    />
    <path
      d="M85.9111 382.382C84.0922 382.382 82.2638 381.965 80.5781 381.118C76.5561 379.1 74.0164 374.985 74.0164 370.485V324.457C55.5801 321.919 38.5511 313.492 25.1599 300.1C8.93502 283.874 0 262.302 0 239.358V180.996C0 133.624 38.5392 95.084 85.9123 95.084H239.108C286.48 95.084 325.02 133.623 325.02 180.996V239.359C325.02 286.731 286.481 325.271 239.108 325.271H166.457L93.0236 380.023C90.9288 381.584 88.4271 382.382 85.9111 382.382ZM85.9123 118.876C51.6592 118.876 23.7918 146.743 23.7918 180.996V239.359C23.7918 255.949 30.2525 271.547 41.9819 283.278C53.7196 295.015 69.321 301.479 85.9123 301.479C92.4812 301.479 97.8082 306.806 97.8082 313.375V346.779L155.4 303.838C157.454 302.306 159.948 301.479 162.511 301.479H239.108C273.361 301.479 301.228 273.612 301.228 239.359V180.996C301.228 146.743 273.361 118.876 239.108 118.876H85.9123Z"
      fill="#5E2A41"
    />
  </svg>
);

export const Camera = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
    <path d="M208,56H180.28L166.65,35.56A8,8,0,0,0,160,32H96a8,8,0,0,0-6.65,3.56L75.71,56H48A24,24,0,0,0,24,80V192a24,24,0,0,0,24,24H208a24,24,0,0,0,24-24V80A24,24,0,0,0,208,56Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H80a8,8,0,0,0,6.66-3.56L100.28,48h55.43l13.63,20.44A8,8,0,0,0,176,72h32a8,8,0,0,1,8,8ZM128,88a44,44,0,1,0,44,44A44.05,44.05,0,0,0,128,88Zm0,72a28,28,0,1,1,28-28A28,28,0,0,1,128,160Z"></path>
  </svg>
);

export const Success = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M38 34C38 36.209 36.209 38 34 38H6C3.791 38 2 36.209 2 34V6C2 3.791 3.791 2 6 2H34C36.209 2 38 3.791 38 6V34Z"
      fill="#77B255"
    />
    <path
      d="M31.28 8.36202C30.124 7.61102 28.576 7.94002 27.822 9.09802L16.936 25.877L11.907 21.227C10.893 20.289 9.31103 20.352 8.37403 21.365C7.43703 22.379 7.49903 23.961 8.51303 24.898L15.722 31.564C16.202 32.009 16.812 32.229 17.418 32.229C18.091 32.229 18.952 31.947 19.517 31.09C19.849 30.584 32.017 11.82 32.017 11.82C32.768 10.661 32.438 9.11302 31.28 8.36202Z"
      fill="white"
    />
  </svg>
);

export const Thought = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M38 14.875C38 11.009 34.866 7.875 31 7.875C29.833 7.875 28.735 8.165 27.768 8.669C25.686 5.842 22.343 4 18.563 4C13.431 4 9.088 7.38 7.64 12.036C4.982 12.653 3 15.03 3 17.875C3 20.854 5.174 23.32 8.021 23.788C8.237 27.737 11.498 30.875 15.5 30.875C17.676 30.875 19.63 29.942 21 28.462C22.37 29.942 24.323 30.875 26.5 30.875C30.643 30.875 34 27.518 34 23.375C34 22.665 33.895 21.981 33.711 21.33C36.23 20.271 38 17.78 38 14.875Z"
      fill="#85C8F8"
    />
    <path
      d="M7 36.875C8.65685 36.875 10 35.5319 10 33.875C10 32.2181 8.65685 30.875 7 30.875C5.34315 30.875 4 32.2181 4 33.875C4 35.5319 5.34315 36.875 7 36.875Z"
      fill="#85C8F8"
    />
  </svg>
);

export const Idea = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M31 13.92C31 20.359 26 21.359 26 27.36C26 30.458 22.877 30.719 20.5 30.719C18.447 30.719 13.914 29.94 13.914 27.358C13.914 21.36 9 20.36 9 13.92C9 7.889 14.285 3 20.083 3C25.883 3 31 7.889 31 13.92Z"
      fill="#FFD983"
    />
    <path
      d="M24.167 35.36C24.167 36.188 21.933 37.86 20 37.86C18.067 37.86 15.833 36.188 15.833 35.36C15.833 34.532 18.066 34.86 20 34.86C21.933 34.86 24.167 34.532 24.167 35.36Z"
      fill="#CCD6DD"
    />
    <path
      d="M24.707 13.153C24.316 12.762 23.684 12.762 23.293 13.153L20 16.446L16.707 13.153C16.316 12.762 15.684 12.762 15.293 13.153C14.902 13.544 14.902 14.176 15.293 14.567L19 18.274V28.86C19 29.413 19.448 29.86 20 29.86C20.552 29.86 21 29.413 21 28.86V18.274L24.707 14.567C25.098 14.176 25.098 13.544 24.707 13.153Z"
      fill="#FFCC4D"
    />
    <path
      d="M26 33.86C26 34.964 25.104 35.86 24 35.86H16C14.896 35.86 14 34.964 14 33.86V27.86H26V33.86Z"
      fill="#99AAB5"
    />
    <path
      d="M13.9989 34.86C13.5189 34.86 13.0949 34.513 13.0139 34.024C12.9229 33.48 13.2909 32.964 13.8359 32.874L25.8359 30.874C26.3799 30.776 26.8959 31.151 26.9859 31.696C27.0769 32.24 26.7089 32.756 26.1639 32.846L14.1639 34.846C14.1089 34.856 14.0529 34.86 13.9989 34.86ZM13.9989 30.86C13.5189 30.86 13.0949 30.513 13.0139 30.024C12.9229 29.48 13.2909 28.964 13.8359 28.874L25.8359 26.874C26.3799 26.777 26.8959 27.151 26.9859 27.696C27.0769 28.24 26.7089 28.756 26.1639 28.846L14.1639 30.846C14.1089 30.856 14.0529 30.86 13.9989 30.86Z"
      fill="#CCD6DD"
    />
  </svg>
);

export const Bug = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.91669 6C10.9167 5 10.1667 2 8.47869 2C6.79069 2 6.91669 4 7.91669 4C8.91669 4 8.91669 6 8.91669 7C8.91669 8 9.91669 6 9.91669 6ZM5.73369 7.72C6.73369 6.72 5.98369 3.72 4.29569 3.72C2.60769 3.72 2.73369 5.72 3.73369 5.72C4.73369 5.72 4.73369 7.72 4.73369 8.72C4.73369 9.72 5.73369 7.72 5.73369 7.72Z"
      fill="#AA8DD8"
    />
    <path
      d="M32.1087 34.856C32.2977 35.664 33.3357 37.136 34.1407 36.947C34.9467 36.757 35.2177 34.976 35.0287 34.17C34.8397 33.362 34.0307 32.863 33.2247 33.053C32.4197 33.243 31.9187 34.05 32.1087 34.856ZM26.6747 35.505C26.6777 36.335 27.3557 38.003 28.1837 38C29.0117 37.996 29.6777 36.323 29.6747 35.494C29.6717 34.665 28.9977 33.997 28.1697 33.999C27.3417 34.003 26.6717 34.676 26.6747 35.505ZM21.4037 33.059C21.0777 33.823 21.0387 35.622 21.7997 35.949C22.5627 36.273 23.8367 35.002 24.1627 34.239C24.4877 33.477 24.1347 32.596 23.3737 32.269C22.6117 31.944 21.7307 32.296 21.4037 33.059ZM16.3727 22.019C15.5607 22.191 14.0647 23.192 14.2337 24.002C14.4067 24.812 16.1787 25.126 16.9907 24.955C17.8017 24.784 18.3197 23.988 18.1507 23.178C17.9787 22.368 17.1857 21.848 16.3727 22.019ZM14.3357 17.019C13.5237 17.191 12.0277 18.192 12.1967 19.002C12.3697 19.812 14.1417 20.126 14.9537 19.955C15.7647 19.784 16.2827 18.988 16.1137 18.178C15.9417 17.368 15.1477 16.848 14.3357 17.019Z"
      fill="#744EAA"
    />
    <path
      d="M29.7457 36C32.5767 36 34.6717 35.123 35.0117 34.973C37.2827 33.963 38.3047 31.304 37.2957 29.034C36.2967 26.784 33.6797 25.759 31.4237 26.72C31.2907 26.77 29.6307 27.369 28.0807 26.648C26.2297 25.787 25.0857 23.339 24.4527 21.439C24.3087 21.009 24.0677 20.165 23.7667 19.123C21.9007 12.652 20.9347 10.838 19.7837 9.30299C16.0727 4.35199 10.1917 4.00699 7.30068 4.58699C4.86368 5.07399 3.28268 7.44499 3.77068 9.88199C4.25868 12.32 6.63068 13.902 9.06668 13.412C9.22868 13.386 11.2707 13.106 12.4907 14.581C13.1337 15.868 14.5707 19.709 15.1207 21.615C15.4667 22.814 15.7497 23.786 15.9147 24.282C17.6877 29.599 20.5807 33.175 24.5177 34.911C26.3697 35.729 28.1717 36 29.7457 36Z"
      fill="#9266CC"
    />
    <path
      d="M9.18372 8C9.736 8 10.1837 7.55228 10.1837 7C10.1837 6.44772 9.736 6 9.18372 6C8.63143 6 8.18372 6.44772 8.18372 7C8.18372 7.55228 8.63143 8 9.18372 8Z"
      fill="#202024"
    />
    <path
      d="M33.4248 34.964C33.3358 34.964 33.2448 34.952 33.1558 34.928C32.6238 34.78 32.3118 34.229 32.4598 33.697C33.2168 30.975 31.6898 29.155 31.6748 29.137C31.3158 28.718 31.3628 28.086 31.7818 27.727C32.1998 27.368 32.8328 27.415 33.1918 27.834C33.2838 27.941 35.4258 30.494 34.3868 34.231C34.2648 34.674 33.8618 34.964 33.4248 34.964ZM26.2258 34.401C25.9808 34.401 25.7358 34.312 25.5418 34.131C25.1388 33.753 25.1178 33.12 25.4948 32.717C26.9798 31.13 26.9478 28.524 26.9468 28.497C26.9308 27.945 27.3658 27.485 27.9188 27.469C28.4608 27.464 28.9318 27.888 28.9468 28.441C28.9508 28.58 29.0198 31.878 26.9558 34.085C26.7588 34.295 26.4918 34.401 26.2258 34.401ZM17.6838 25.705C17.2038 25.705 16.7798 25.358 16.6988 24.868C16.6088 24.324 16.9768 23.808 17.5218 23.719C20.5958 23.209 22.2958 21.342 22.3128 21.323C22.6778 20.913 23.3108 20.874 23.7218 21.239C24.1338 21.604 24.1748 22.232 23.8128 22.645C23.7258 22.744 21.6568 25.06 17.8488 25.691C17.7928 25.701 17.7378 25.705 17.6838 25.705ZM20.6048 30.562C20.1798 30.562 19.7858 30.29 19.6508 29.864C19.4848 29.338 19.7768 28.775 20.3028 28.609C22.9958 27.756 24.1828 25.779 24.1948 25.759C24.4748 25.282 25.0868 25.122 25.5628 25.402C26.0398 25.681 26.1988 26.295 25.9198 26.77C25.8568 26.878 24.3248 29.433 20.9068 30.514C20.8058 30.547 20.7038 30.562 20.6048 30.562ZM15.9858 19.892C15.4898 19.892 15.0588 19.523 14.9948 19.017C14.9258 18.469 15.3138 17.969 15.8618 17.899C18.5508 17.559 20.5188 15.928 20.5378 15.911C20.9598 15.557 21.5908 15.608 21.9458 16.03C22.3018 16.451 22.2508 17.08 21.8308 17.437C21.7338 17.52 19.4108 19.466 16.1128 19.883C16.0698 19.889 16.0278 19.892 15.9858 19.892ZM14.1648 15.125C13.7398 15.125 13.3458 14.852 13.2108 14.424C13.0458 13.897 13.3378 13.336 13.8648 13.17C16.9948 12.187 18.1178 10.478 18.1638 10.406C18.4588 9.94397 19.0748 9.80097 19.5388 10.094C20.0038 10.386 20.1498 10.993 19.8628 11.46C19.8028 11.557 18.3568 13.856 14.4638 15.079C14.3648 15.11 14.2638 15.125 14.1648 15.125Z"
      fill="#744EAA"
    />
  </svg>
);
