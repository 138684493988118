'use client';

import { setupI18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

type LocaleProviderProps = {
  children: React.ReactNode;
  messages?: any;
  locale: string;
};

export function LocaleProvider({ children, messages, locale }: LocaleProviderProps) {
  return (
    <I18nProvider
      i18n={setupI18n({
        locale,
        messages: { [locale]: messages },
      })}
    >
      {children}
    </I18nProvider>
  );
}
